/* done */
.tutor-dashboard-page-header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8vh;
  border-bottom: 1px solid #e0e0e0;
  position: fixed;
  flex: 1;
  background-color: #f9f9f9;
  width: 83.5%;
  z-index: 100;
}

.tutor-dashboard-page-header-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto;
}

.side-menu-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 2%;
}

.tutot-right-side-container {
  background-color: #f9f9f9 !important;
  width: 100%;
}

.side-menu-children-data {
  background-color: #f9f9f9;
  min-height: 92vh;
  padding: 12vh 30px 50px 30px;
}

.side-menu-children-chat-data {
  background-color: #f9f9f9;
  padding: 8vh 0px 0px 0px;
}

.badge-color {
  color: #98189a !important;
}

.notification-model-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.notification-model-username {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  color: #303030;
}
.notification-model-complete-title {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  color: #303030;
  margin-left: 5px;
  margin-right: 5px;
}
.notification-model-date {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  color: #303030;
}
.notification-model-description {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  color: #303030;
  margin-top: 30px;
  margin-bottom: 20px;
}
.css-10tn45-MuiBadge-badge {
  background-color: #98189a !important;
  border: 1px solid white !important;
}

.side-menu-more-pages {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.drawer-website-pages-main {
  border: 1px solid white;
  border-radius: 5px;
  margin: 10px;
  padding: 5px;
}
.side-menu-header-username {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #303030;
  margin: 0px 20px 0px 10px;
}

.side-menu-header-profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  cursor: pointer;
  /* border: 1px solid #98189a; */
}

.first-tutor-doashboard-notification-wraper {
  height: 20rem;
  width: 14rem;
  overflow: hidden;
}

.tutor-dashboard-page-header-content-wrapper-profile-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.notification-clear-all-button {
  width: 100% !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 25px !important;
  text-transform: capitalize !important;
  color: #000 !important;
  justify-content: center !important;
  padding: 10px 0px !important;
  border-bottom: 1px solid #e0e0e0 !important;
}

.notification-clear-all-button:hover {
  color: #98189a !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
}

.side-menu-notification-main {
  overflow: auto;
  height: auto;
  height: 17rem;
}

.side-menu-notification-main::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.side-menu-notification-card {
  border-bottom: 1px solid #e0e0e0;
  padding: 2% 5%;
  cursor: pointer;
}

.side-menu-notification-heading {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: #303030;
}

.side-menu-notification-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: #303030;
}

.side-menu-notification-date {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: #303030;
  margin-top: 5px;
}

.app-bar-component-drawer-close-btn {
  width: 40px;
  height: 40px;
  border: 1px solid white;
  cursor: pointer !important;
  background-color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.drawer-header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8vh;
  border-bottom: 1px solid #e0e0e0;
  padding: 0px 5%;
}

@media only screen and (max-width: 1199px) {
  .tutor-dashboard-page-header-container {
    width: 100%;
  }
}
