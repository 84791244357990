.students-details-card {
  border-bottom-width: 1px;
  border-bottom-color: #cbcbcb;
  border-bottom-style: solid;
  padding: 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.students-details-title {
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 10px;
  color: black;
}
.student-details-value {
  text-transform: capitalize;
  margin-bottom: 10px;
  color: #000;
  opacity: 0.8;
}
.student-details-value-email {
  text-transform: lowercase;
  margin-bottom: 10px;
  color: #000;
  opacity: 0.8;
}
.student-details-passed-button {
  font-weight: 600 !important;
  background-color: #f39c12 !important;
  width: 100%;
  margin-top: 20px !important;
  color: white !important;
  text-transform: none !important;
  height: 40px !important;
}
.student-details-leave-button {
  font-weight: 600 !important;
  background-color: #dd4b39 !important;
  width: 100%;
  margin-top: 20px !important;
  color: white !important;
  text-transform: none !important;
  height: 40px !important;
}
.edit-button {
  font-weight: 600 !important;
  background-image: linear-gradient(90deg, #293b90, #98189a) !important;
  width: 100%;
  margin-top: 10px !important;
  color: white !important;
  text-transform: none !important;
  height: 40px !important;
  margin-bottom: 20px !important;
}
.model-heading {
  font-weight: bold;
}
.qr-code {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.months {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.month {
  outline: none;
  border: none;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  padding: 5px;
  margin: 2px;
  border-radius: 5px;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.month > p {
  margin-right: 5px;
  text-transform: capitalize;
  color: #000;
  opacity: 0.8;
}
