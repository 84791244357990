.tutor-panel-sider-container {
  background-color: #98189a;
  /* box-shadow: 4px 20px 10px rgba(0, 0, 0, 0.2); */
  height: 100vh;
  z-index: 1;
  direction: rtl;
  width: 16.5%;
}

.first-tutor-panel-sider-log-out-container {
  border-top: 1px solid #e5e5e5;
  height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  background-color: white;
}

.side-menu-ul {
  width: 100%;
}

.side-menu-data-list-main {
  display: flex;
  overflow-y: scroll;
  height: 87vh;
  padding: 0px 20px;
  background-color: #98189a;
}

.side-menu-data-list-main::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.logout-button {
  width: 100% !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 25px !important;
  text-transform: capitalize !important;
  color: #303030 !important;
  justify-content: space-between !important;
}

.logout-button:hover {
  color: #98189a !important;
}

.side-menu-icon:hover {
  color: #98189a !important;
}

.side-menu-page {
  width: 100% !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  text-transform: capitalize !important;
  color: #fff !important;
  justify-content: flex-start !important;
}

.side-menu-active-page {
  width: 100% !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  text-transform: capitalize !important;
  color: #98189a !important;
  justify-content: flex-start !important;
  background-color: white !important;
}

.side-menu-page:hover {
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.465) !important;
}

.side-menu-page-title {
  margin-left: 10px;
}

.tutor-panel-sider-list-item {
  list-style: none;
  margin-top: 10px;
  width: 100%;
}

.tutor-panel-sider-content-wraper {
  direction: ltr;
}

.tutor-panel-sider-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8vh;
  border-bottom: 1px solid #e0e0e0;
  background-color: white;
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  background-color: #98189a !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 0px !important;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 16px;
  min-height: 16px;
  max-width: calc(20% - 32px) !important;
  max-height: calc(100% - 32px);
  outline: 0;
  left: 16.5% !important;
}

.css-1dmzujt {
  background-color: #98189a !important;
  max-width: calc(20% - 32px) !important;
  left: 16.5% !important;
}

@media only screen and (max-width: 1500px) {
  .first-tutor-panel-sider-log-out-container {
    padding: 0px 5px;
  }

  .side-menu-data-list-main {
    display: flex;
    overflow-y: scroll;
    height: 87vh;
    padding: 0px 5px;
  }

  .side-menu-page {
    font-size: 14px !important;
    line-height: 19px !important;
  }

  .side-menu-page-title {
    margin-left: 5px;
  }
}

@media only screen and (max-width: 1199px) {
  .first-tutor-panel-sider-log-out-container {
    padding: 0px 20px;
  }

  .side-menu-data-list-main {
    display: flex;
    overflow-y: scroll;
    height: 87vh;
    padding: 0px 20px;
  }

  .side-menu-page {
    font-size: 16px !important;
    line-height: 22px !important;
  }

  .side-menu-page-title {
    margin-left: 10px;
  }
}
