.login-container {
  background-color: white;
  height: 100vh;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form-container {
  background: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* width: 100%; */
  padding: 40px 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.login-from-heading {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}
.input-title {
  font-weight: 600;
  font-size: 16;
  margin-bottom: 10px;
}
/* input {
  background: none;
  outline: none;
  border: none;
  border: 1px solid #98189a;
  height: 40px;
  margin-top: 10px;
  padding: 0px 15px;
  font-weight: 500;
  border-radius: 5px;
} */
input,
select {
  border: none;
  overflow: hidden;
  background: none;
  outline: none;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  height: 48px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-transform: none;
  color: #000;
}
.error {
  color: #fb1313;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}
.error-message {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-transform: lowercase;
}
.success-message {
  color: #0e950b;
  font-size: 16px;
  font-weight: 500;
  text-transform: lowercase;
}
.success {
  color: #0e950b;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}
/* linear-gradient(90deg, #293b90, #98189a) !important; */
.student-details-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.student-details-status-box {
  padding: 5px 15px;
  border-radius: 5px;
}
.student-details-status-box > p {
  color: white;
  text-transform: capitalize;
  font-size: 16px;
}
