.header-box {
  background: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.dashboard-box {
  background: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
}
.header-box > img {
  height: 100px;
}
.header-box > h1 {
  font-size: 20px;
  margin-top: 15px;
}
.header-box-loader {
  color: #98189a !important;
  width: 20px !important;
  height: 20px !important;
}
.css-y8ay40-MuiTableCell-root {
  font-weight: bold !important;
}
.css-1ex1afd-MuiTableCell-root {
  text-transform: capitalize !important;
}
.table-status-box {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0px;
}
.table-status-box > p {
  color: white;
}
.view-button {
  font-weight: 600 !important;
  background-image: linear-gradient(90deg, #293b90, #98189a) !important;
  width: 100%;
  color: white !important;
  text-transform: none !important;
  height: 25px !important;
}
.back-button {
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding-left: 0px !important;
}
.dashboard-status-box-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
.dashboard-status-box-main>div>p{
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 7px;
}
.dashboard-status-box-active {
  background-color: #00a75f;
}
.dashboard-status-box-graduated {
  background-color: #f39c12;
}
.dashboard-status-box-left {
  background-color: #dd4b39;
}
.dashboard-status-box-main > div > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  padding: 5px 15px;
}
.dashboard-status-box-main > div > div > img {
  height: 20px;
}
.dashboard-status-box-main > div > div>p {
  color: white;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 700;
}
.dashboard-status-box-icon{
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

}
.dashboard-status-box-icon>img{
  height: 100px;
}
@media only screen and (max-width: 1200px) {
  .header-box > img {
    height: 80px;
  }
  .header-box > h1 {
    font-size: 16px;
    margin-top: 15px;
  }
  .header-box-loader {
    width: 15px !important;
    height: 15px !important;
  }
}
