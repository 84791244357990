.add-caste-button {
  font-weight: 600 !important;
  background-image: linear-gradient(90deg, #293b90, #98189a) !important;
  width: 100%;
  margin-top: 20px !important;
  color: white !important;
  text-transform: none !important;
  height: 48px !important;
}
.loading {
  margin-top: 20px;
}
.caste-box {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.caste-box-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.caste-box > p {
  font-size: 16px;
  text-transform: capitalize;
}
.delete-button {
  min-width: 20px !important;
  margin-left: 15px !important;
}
select > option > span {
  text-transform: capitalize !important;
}
@media only screen and (max-width: 599px) {
  .add-caste-button {
    margin-top: 0px !important;
  }
}
